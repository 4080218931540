<template>
    <div class="modal__input-area">
         <div class="modal__input-container">
            <input id="id-password" v-model="password" class="modal__input" placeholder=" " type="password"> 
                
            <label class="modal__label" for="id-password">password</label>
        </div>
        
            <div class="modal__buttons-container">
                <button @click="deleteBranch" class="modal__button modal__button--delete" >
                    <div v-if="loading"><filling-circle :size="16" /></div>
                    <div style="padding-left:1em;">Delete</div>
                </button>
            </div>
    </div>
</template>

<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'

export default {
    data(){
        return {
            loading: false,
            password:"",
        }
    },
    components:{
        FillingCircle
    },
    computed:{
        id(){
            return this.$route.params.subId
        }
    },
    methods:{
        deleteBranch(){
            console.log(this.id)
            this.loading = true
            this.$store.dispatch({
                type:'branches/deleteBranch',
                branchId: this.id,
                password: this.password
            }).then(()=>{
                this.loading=false     
                this.$router.push({name:'Branches'})
                this.$notify({
                    title: `Branch Deleted`,
                    text:`Successfully Deleted branch`,
                    type:"success"
                })
            }).catch(error=>{
                this.$notify({
                    title: "Failed to Delete Branch",
                    text: error,
                    type:"error"
                })
                console.log(error.response.data)
            })
        },
    },
    mounted(){
        console.log(this.id)
    },
    

}
</script>
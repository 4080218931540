<template>
    <div class="modal-container">
		<div class="modal">
			<div class="modal__title">
				<h1>{{ title }}</h1> 
				<button class="modal__close" @click="closeModal">
					<img class="modal__close-image" src="@/assets/images/close.svg" alt="">
				</button>
			</div>
			<branch-delete v-if="deleteModal"/>
			<branch-input v-else @delete="deleteModal=true"  />

		</div>
	</div>
</template>
<script>

import BranchInput from '@/components/Branches/modals/BranchEdit.vue'
import BranchDelete from '@/components/Modals/ModalInputs/Delete.vue'
export default {
	data(){
		return{
			deleteModal:false,
		}
	},
	components:{ BranchInput, BranchDelete },
	computed:{ 
		title(){
			return this.deleteModal ? "Delete Branch" : "Branch Details"
		},
	},
	methods:{
		closeModal(){
			window.history.length > 1 ? this.$router.go(-1) : this.$router.push({name:'branches'})
		}
	},
}
</script>


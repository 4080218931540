<template>
    <div class="modal__input-area">
        <div v-if="loading" class="modal__loading">
            <filling-circle :size="42" color="#057DCD"/>
        </div>
        <div v-else>
            <image-upload :id="branchDetails.id" @get-image="setImage"  :image="branchDetails.logo"/>

            <edit-box name="name" requestKey="name" :id="branchDetails.id" :defaultValue="branchDetails.name" :description="false" type="text"/>
            <edit-box name="location" requestKey="location" :id="branchDetails.id"  :defaultValue="branchDetails.location" :description="false"  type="text"/>
            <edit-box name="description" requestKey="description" :id="branchDetails.id"  :defaultValue="branchDetails.description" :description="true" type="description"/>
            
            <div class="modal__buttons-container">
                <button class="modal__button modal__button--delete" @click="openDelete">delete</button>
            </div>
        </div>
	</div>
</template>

<script>
import ImageUpload from '@/components/Modals/ModalInputs/ImageUpload.vue'
import EditBox from '@/components/Modals/ModalInputs/EditBox.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{ImageUpload, EditBox,  FillingCircle },
    data(){
        return {
            loading:true
        }
    },
    computed:{
        id(){
            return this.$route.params.subId
        },

        branchDetails(){
            return this.$store.state.branches.branchDetails
        },
        
    },
    methods:{
        openDelete(){
            this.$emit('delete')
        },
        setImage(image){
           this.image = image
           console.log('this image', this.image)
        },
    },
    mounted(){
        this.loading = true
        this.$store.dispatch({
            type:'branches/loadBranchInstance',
            id: this.id,
        }).then( ()=>{
            this.loading = false
            console.log(this.branchDetails.name)
        }).catch(error=>{
            console.log(error)
            this.$router.go(-1)
        })
    }
}
</script>